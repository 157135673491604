<script setup lang="ts">
import { Calendar, InfoIcon, MessageSquareMore } from 'lucide-vue-next'
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  isCollects: {
    type: Boolean,
    required: false,
    default: true,
  },
  collects: {
    type: Array as PropType<CollectGraphql[]>,
    default: () => [],
    required: true,
  },
  collectsCount: {
    type: Number,
    default: 0,
    required: true,
  },
})

const { filters, page, collectsLoading, isEmptyFilters } = storeToRefs(useCollectsStore())
const { loadCollects } = useCollectsStore()

const router = useRouter()
const { t } = useI18n()

const { collects } = toRefs(props)

function newCollect() {
  router.push({ name: 'collects-new' })
}

onMounted(async () => {
  await loadCollects()
})
</script>

<template>
  <div v-if="!collectsLoading" class="flex flex-col gap-4 p-4 pb-0 grow overflow-auto bg-inherit">
    <div v-if="collects" class="flex items-center justify-between gap-2">
      <div class="flex gap-1">
        <RecollectionSortingPopOver v-model="filters.sort" />
        <RecollectionStatusPopOver v-model="filters.statuses" />
      </div>
      <button class="hidden btn btn-sm btn-primary md:flex font-medium" @click="newCollect()">
        <Calendar :size="18" />
        <p>{{ t("global.new_collect") }}</p>
      </button>
    </div>
    <div v-if="collects?.length > 0" class="flex flex-col gap-4 overflow-auto grow p-1">
      <template v-for="collect in collects" :key="collect.id">
        <RecollectionMultiMaterialCard :collect="collect" />
      </template>
    </div>
    <template v-else>
      <div v-if="isEmptyFilters">
        <div class="flex flex-col grow items-start gap-4 border border-[#71E4BA] rounded bg-[#ECFDF6] p-6 md:flex-row">
          <img src="/assets/illustrations/truck-wastex.png" class="h-20 w-25">
          <div class="h-full flex flex-col items-start justify-center gap-3">
            <p class="text-xl text-[#374151] font-semibold">
              {{ t("global.no_services_for_the_moment") }}
            </p>
            <button class="w-fit btn-primary" @click="newCollect()">
              <Calendar :size="18" />
              <p>{{ t("global.schedule_a_service") }}</p>
            </button>
          </div>
        </div>
        <div
          class="flex flex-col items-start gap-4 border border-neutral rounded bg-info p-4 md:flex-row md:items-center md:justify-between md:gap-22"
        >
          <div class="flex items-center gap-2">
            <MessageSquareMore :size="18" />
            <p class="text-lg">
              {{ t("global.problem_or_difficulty_contact_us") }}
            </p>
          </div>
          <a href="https://calendly.com/laure-wastextech " target="_blank" class="btn btn-sm btn-secondary">
            <p>{{ t("global.contact_us") }}</p>
          </a>
        </div>
      </div>
      <div v-else class="grow">
        <div role="alert" class="alert alert-warning">
          <InfoIcon :size="24" />
          <span>{{ t("collect.noCollectsForFilters") }}</span>
        </div>
      </div>
    </template>
    <EPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
  </div>
  <div v-else class="flex grow items-center justify-center">
    <Loader class="mb-2 h-8 w-8 text-gray-500" />
  </div>
</template>
