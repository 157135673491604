import gql from 'graphql-tag'

export const GET_CLIENTS = gql`
query Clients($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    count
    collection {
      id
      contactFirstName
      contactLastName
      iban
      isConnectedToTrackdechets
      name
      phoneNumber
      siret
      slug
      bic
      documents { 
        collection {
          name
          signedUrl
          type
          id
          extension
          createdAt
        }
      }
      sites {
        collection {
          name
          id
        }
      }
      autosignEnabledForTrackdechets
    }
  }
}
`

export const GET_CLIENT = gql`
query Clients($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    count
    collection {
      id
      contactFirstName
      contactLastName
      iban
      isConnectedToTrackdechets
      name
      phoneNumber
      siret
      slug
      bic
      documents { 
        collection {
          name
          signedUrl
          type
          id
          extension
          createdAt
        }
      }
      sites {
        collection {
          name
          id
        }
      }
      autosignEnabledForTrackdechets
      ... on ProducerGraphql {
        recyclers {
          collection {
            id
            name
            contactFirstName
            contactLastName
          }
        }
      }
      ... on RecyclerGraphql {
        producers {
          collection {
            id
            name
            contactFirstName
            contactLastName
          }
        }
      }
    }
  }
}
`

export const GET_CLIENTS_FOR_SELECT = gql`
query Clients($pagination: PaginationArgs!, $filters: ClientFilterGraphql!) {
  clients(pagination: $pagination, filters: $filters) {
    collection {
      id
      contactFirstName
      contactLastName
      name
    }
  }
}
`

export const UPDATE_CLIENT = gql`
mutation UpdateClient($input: ClientInput!) {
  updateClient(input: $input) {
    id
    name
    slug
    isConnectedToTrackdechets
    autosignEnabledForTrackdechets
    siret
    iban
    contactFirstName
    contactLastName
    bic
    phoneNumber
    documents {
      collection {
        name
        signedUrl
        type
        id
        extension
        createdAt
      }
    }
    address {
      line1
      line2
      postalCode
      city
      country
    }
    sites {
      count
      collection {
        id
        name
        address
      }
    }
  }
}`
