<script setup lang="ts">
import {
  Clock,
  Plus,
} from 'lucide-vue-next'

const { t } = useI18n()
const { getCollects, collects } = storeToRefs(useCollectsStore())
const { isProducer, stores_loaded } = storeToRefs(useUsersStore())

const isLoading = ref<boolean>(false)
const noCollects = ref<boolean>(false)
const header = inject('header') as any

const router = useRouter()

const tabs = reactive({
  list: [t('global.collections'), t('global.incidents')],
  selected: 0,
})

async function loadData() {
  isLoading.value = true
  header.title = t('collect.collects')

  if (!stores_loaded.value)
    return
  if (getCollects.value.length === 0) {
    noCollects.value = true
  }
  isLoading.value = false
}

function newCollect() {
  router.push('/collects/new')
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <div class="max-w-[800px] md:mx-auto w-full flex flex-col grow h-[calc(100%-84px)] overflow-auto">
    <div class="mx-3 flex items-center justify-center">
      <ETab v-model="tabs.selected" :list-tabs="tabs.list" />
    </div>
    <div v-if="!isLoading" class="h-auto flex flex-1 flex-col overflow-auto">
      <template v-if="tabs.selected === 0">
        <Recollection :collects="getCollects" :collects-count="collects.count" :is-collects="noCollects" />
      </template>
      <template v-if="tabs.selected === 1">
        <Incidents />
      </template>

      <div v-if="isProducer && tabs.selected === 0" class="pointer-events-none fixed inset-0 z-10">
        <div
          class="pointer-events-auto absolute bottom-20 right-5 h-12 w-12 flex flex-col cursor-pointer items-center justify-center gap-2 rounded-full bg-[#15C28E] p-2.5 text-3xl text-white shadow md:hidden"
          @click="newCollect()"
        >
          <Plus :size="18" />
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
