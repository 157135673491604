export function getEnvironment(): 'development' | 'staging' | 'production' {
  const hostname = window.location.hostname
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return 'development'
  }
  else if (hostname.includes('staging')) {
    return 'staging'
  }
  else {
    return 'production'
  }
}
