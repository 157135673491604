async function checkScriptAndShowButton(scriptSrc) {
  try {
    const response = await fetch(scriptSrc, { method: 'HEAD', cache: 'no-cache' })
    if (!response.ok) {
      document.getElementById('reload_button').style.display = 'flex'
    }
  }
  catch (error) {
    console.error('Error fetching the script:', error)
    document.getElementById('reload_button').style.display = 'flex'
  }
}

async function findScriptAndAct() {
  const scripts = document.getElementsByTagName('script')
  const pattern = /\/assets\/index-.*\.js/
  for (const script of scripts) {
    if (pattern.test(script.src)) {
      console.log('Found script:', script.src)
      await checkScriptAndShowButton(script.src)
      return
    }
  }
  console.log('No matching script found')
}

function emptyCacheAndReload() {
  console.log('Clearing local and session storage, and attempting to clear service worker caches...')
  localStorage.clear()
  sessionStorage.clear()
  if ('caches' in window) {
    caches.keys().then((cacheNames) => {
      for (const cacheName of cacheNames) {
        caches.delete(cacheName)
      }
    })
  }
  location.reload()
}

window.onload = findScriptAndAct
