import { provideApolloClient } from '@vue/apollo-composable'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { defineStore } from 'pinia'
import { CLIENTS_QUERY_LIST } from '~/queries/clients'
import apolloClient from '../composables/graphql'

provideApolloClient(apolloClient)

export const orgStore = defineStore({
  id: 'org',
  state: () => ({
    org: {
      clients: [] as Array<any>,
      selected: 0,
    },
    overlayedClientToken: '',
    overlayedUserId: '',
    clientTokenRefreshKey: 0,
  }),
  getters: {
    getOrgs(): any {
      return this.org
    },
    getOrgsAsOptions(): any {
      if (this.org.clients) {
        return [
          { label: 'All Clients', value: 0 },
          ...this.org?.clients?.map((item) => {
            return { label: item.name, value: item.id, id: item.id, client_type: item.client_type }
          }),
        ]
      }
      return [{
        label: 'All Clients',
        value: 0,
      }, []]
    },
  },

  actions: {
    getProducerOrgs(): any[] {
      return this.org.clients.filter(item => item.client_type === 'producer')
    },

    getRecyclerOrgs(): any[] {
      return this.org.clients.filter(item => item.client_type === 'recycler')
    },
    async loadOrg(): Promise<any> {
      const { user } = storeToRefs(useUsersStore())
      if (user.value) {
        const client_list = await apolloClient.query({ query: CLIENTS_QUERY_LIST })
        this.org.clients = client_list?.data?.clients
        if (this.org.selected === undefined)
          this.org.selected = this.org.clients[0]
      }
      return this.org
    },
    // async setOverlayedClientToken(): Promise<any> {
    //   const token = await apolloClientNest.mutate({
    //     mutation: GET_OVERLAYED_CLIENT_TOKEN,
    //     variables: { input: { userId: this.overlayedUserId } },
    //   })

    //   signInWithCustomToken(getAuth(), token.data.logAs.userJWT).then((userCredential) => {
    //     adminTokenStore().overlayedUserToken = userCredential.user.accessToken
    //     adminTokenStore().isConnectedAsUser = !adminTokenStore().isConnectedAsUser
    //   })
    //   return this.overlayedClientToken
    // },
    async connectUserAutomatically(token: string): Promise<any> {
      signInWithCustomToken(getAuth(), token)
    },
  },
})
