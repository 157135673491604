import { gql } from '@apollo/client/core'

export const CREATE_CLIENT = gql`
mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    bic
    autosignEnabledForTrackdechets
    contactFirstName
    contactLastName
    iban
    id
    name
    phoneNumber
    siret
  }
}
`

export const CLIENTS_QUERY_LIST = gql`
  query ClientQuery {
    clients {
      id
      name
      slug
      client_type
      sites {
        id
        name
        address
        postal_code
        additional_information
      }
      clients_data {
        id
        siret
        iban
        bic
        contact_first_name
        contact_last_name
      }
      waste_producer {
        id
        client {
          id
          name
          client_type
        }
      }
      waste_recycler {
        id
        wr_client {
          id
          name
          client_type
        }
      }
    }
  }
`

export const CLIENTS_QUERY_UPDATE = gql`
  mutation UpdateClient($id: Int!, $object: clients_set_input!, $clients_data: clients_data_set_input!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      name
      slug
      client_type
      clients_data {
        id
        siret
        iban
        bic
        contact_first_name
        contact_last_name
      }
      waste_producer {
        id
        client {
          id
          name
          client_type
        }
      }
      waste_recycler {
        id
        wr_client {
          id
          name
          client_type
        }
      }
    }
    update_clients_data(where: { client_id: { _eq: $id } }, _set: $clients_data) {
      affected_rows
    }
  }
`

export const CLIENTS_QUERY_INSERT = gql`
  mutation InsertClient($object: clients_insert_input!) {
    insert_clients_one(object: $object) {
      id
      name
      slug
      client_type
      clients_data {
        id
        siret
        iban
        bic
        contact_first_name
        contact_last_name
      }
      waste_recycler {
        wp_id
        wr_id
      }
    }
  }
`

export const CLIENTS_QUERY_DELETE = gql`
  mutation DeleteClient($id: Int!) {
    delete_clients_data(where: { client_id: { _eq: $id } }) {
      affected_rows
    }
    delete_collection_coupon_raw_materials_container(where: { collection_coupon: { client_id: { _eq: $id } } }) {
      affected_rows
    }
    delete_collection_coupons(where: { client_id: { _eq: $id } }) {
      affected_rows
    }
    delete_raw_materials_containers(where: { raw_material: { client_id: { _eq: $id } } }) {
      affected_rows
    }
    delete_raw_materials(where: { client_id: { _eq: $id } }) {
      affected_rows
    }
    delete_raw_materials_data(where: { client_id: { _eq: $id } }) {
      affected_rows
    }
    delete_clients_by_pk(id: $id) {
      id
    }
  }
`
export const ADD_PRODUCERS_TO_RECYCLER = gql`
mutation AddProducersToRecycler($input: ProducersToRecyclerInput!) {
  addProducersToRecycler(input: $input) {
    id
  }
}
`

export const ADD_RECYCLERS_TO_PRODUCER = gql`
mutation AddRecyclersToProducer($input: RecyclersToProducerInput!) {
  addRecyclersToProducer(input: $input) {
    id
  }
}
`

export const REMOVE_RECYCLERS_FROM_PRODUCER = gql`
mutation AddRecyclersToProducer($input: RecyclersToProducerInput!) {
  removeRecyclersFromProducer(input: $input) {
    id
  }
}
`

export const REMOVE_PRODUCERS_FROM_RECYCLER = gql`
mutation RemoveProducersFromRecycler($input: ProducersToRecyclerInput!) {
  removeProducersFromRecycler(input: $input) {
    id
  }
}
`

export const DELETE_WR_WP = gql`
  mutation DeleteWRWP($ids: [Int]!) {
    delete_wp_wr(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`

export const DELETE_WASTE_RECYCLER = gql`
  mutation DeleteWasteRecycler($_eq: Int!) {
    delete_wp_wr(where: { wr_id: { _eq: $_eq } }) {
      affected_rows
    }
  }
`

export const RECYCLERS_QUERY_GET_WITH_SITES = gql`
  query Recycler {
    sites(where: { client: { client_type: { _eq: recycler } } }) {
      id
      name
      address
      postal_code
      additional_information
      client {
        id
        name
        slug
        waste_producer {
          id
          client {
            id
          }
        }
        waste_recycler {
          id
          wr_client {
            id
          }
        }
      }
    }
  }
`

export const GET_OVERLAYED_CLIENT_TOKEN = gql`
  mutation LogAs($input: LogAsInput!) {
    logAs(input: $input) {
      userJWT
    }
  }
`
