export const isDark = useDark({
  storageKey: 'eugene-theme-appearance',
  selector: 'html',
  attribute: 'data-theme',
  valueDark: 'dracula',
  valueLight: 'mytheme',
})

export const toggleDark = useToggle(isDark)

export const logo = computed(() => isDark.value ? '/assets/logo-eugene-wh.svg' : '/assets/logo-eugene-blue.svg')
export const picto = computed(() => isDark.value ? '/assets/picto-eugene-wh.svg' : '/assets/picto-eugene-blue.svg')
