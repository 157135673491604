<script lang="ts" setup>
import { CircleOffIcon } from 'lucide-vue-next'
import { computed, ref, watch } from 'vue'

interface Column {
  key: string
  label: string
  thClass?: string
  tdClass?: string
}

interface Props {
  columns: Column[]
  rows: any[]
  hover?: boolean
  selectable?: boolean
  wrapperClass?: string
  tableClass?: string
  theadClass?: string
  thClass?: string
  trClass?: string
  tdClass?: string
  modelValue?: any[]
}

const props = withDefaults(defineProps<Props>(), {
  hover: false,
  selectable: false,
  wrapperClass: '',
  tableClass: '',
  theadClass: '',
  thClass: '',
  trClass: '',
  tdClass: '',
  modelValue: () => [],
  rows: () => [],
})

const emit = defineEmits(['update:modelValue', 'rowSelected'])

const selectedRows = ref<any[]>(props.modelValue || [])

watch(() => props.modelValue, (newValue) => {
  selectedRows.value = newValue || []
})

watch(selectedRows, (newValue) => {
  emit('update:modelValue', newValue)
})

const allSelected = computed(() => {
  return props.rows.length > 0 && selectedRows.value.length === props.rows.length
})

function toggleAllRows() {
  if (allSelected.value) {
    selectedRows.value = []
  }
  else {
    selectedRows.value = [...props.rows]
  }
}

function selectRow(row: any) {
  if (props.selectable) {
    if (selectedRows.value.includes(row)) {
      selectedRows.value.splice(selectedRows.value.indexOf(row), 1)
    }
    else {
      selectedRows.value.push(row)
    }
  }
  emit('rowSelected', row)
}
</script>

<template>
  <div class="overflow-x-auto bg-base-100 rounded-md text-base-content" :class="[wrapperClass]">
    <table class="table table-sm table-pin-cols table-pin-rows" :class="[tableClass]">
      <thead :class="theadClass" class="bg-base-200">
        <tr>
          <td v-if="selectable" class="w-10" :class="[thClass]">
            <input type="checkbox" class="checkbox checkbox-sm" :checked="allSelected" @change="toggleAllRows">
          </td>
          <td v-for="(column, index) in columns" :key="index" :class="[thClass, column.thClass]">
            <slot :name="`header-${column.key}`" :column="column">
              {{ column.label }}
            </slot>
          </td>
          <th v-if="$slots['row-actions']" class="text-right" :class="[thClass]">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex" class="text-base-content" :class="[{ 'hover:bg-base-200': hover }, { 'cursor-pointer': selectable }, trClass, row.trClass]" @click="selectRow(row)">
          <td v-if="selectable" class="w-10" :class="[tdClass]">
            <label>
              <input v-model="selectedRows" type="checkbox" class="checkbox checkbox-sm" :value="row">
            </label>
          </td>
          <td v-for="(column, colIndex) in columns" :key="colIndex" :class="[tdClass, column.tdClass]">
            <slot :name="`cell-${column.key}`" :row="row" :column="column" :value="row[column.key]">
              {{ row[column.key] }}
            </slot>
          </td>
          <th v-if="$slots['row-actions']" class="text-right" :class="[tdClass]">
            <slot name="row-actions" :row="row" :index="rowIndex" />
          </th>
        </tr>
      </tbody>
      <tfoot v-if="$slots.footer">
        <tr>
          <th :colspan="columns.length + (selectable ? 1 : 0) + ($slots['row-actions'] ? 1 : 0)">
            <slot name="footer" />
          </th>
        </tr>
      </tfoot>
    </table>
    <template v-if="rows.length === 0">
      <div class="flex grow justify-center items-center p-4">
        No items
        <CircleOffIcon class="ml-1" :size="18" />
      </div>
    </template>
  </div>
</template>
