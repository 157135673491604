import { useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints({
  mobile: 0, // optional
  tablet: 640,
  laptop: 1024,
  desktop: 1280,
})

const isMobileOpen = ref(false)
const isDesktopOpen = ref(false)

export const activeBreakpoint = breakpoints.active()

export const isMobile = breakpoints.between('mobile', 'laptop')
export const isDesktop = breakpoints.desktop
export { isDesktopOpen, isMobileOpen }
