import type { ApolloQueryResult, FetchResult } from '@apollo/client'
import type { GraphQLFormattedError } from 'graphql'
import type { Mutation, Query } from '~/types/graphql-backend-types/gql-types'
import apolloClientMicroOrm from './graphql-nest'

type MutationResultWithValidation = FetchResult<Mutation> & {
  validationError: string | null | undefined
}

export function useGqlMikro() {
  const query = async (options: { query: any, variables?: any }): Promise<ApolloQueryResult<Query>> => {
    const { data, errors, ...rest }: ApolloQueryResult<Query> = await apolloClientMicroOrm.query(options)

    return { data, errors, ...rest }
  }

  const mutate = async (options: { mutation: any, variables?: any }): Promise<MutationResultWithValidation> => {
    const result: FetchResult<Mutation> = await apolloClientMicroOrm.mutate(options)

    const errors = result?.errors as GraphQLError[]
    let validationError: string | null | undefined = null
    if (errors && errors.length) {
      const isValidationError = errors[0].extensions.originalError
      validationError = isValidationError ? errors[0].extensions.originalError?.message[0] : null
    }
    return { ...result, validationError }
  }

  return { query, mutate }
}
interface GraphQLValidationError {
  message: string[]
  error: string
  statusCode: number
}

interface GraphQLError extends GraphQLFormattedError {
  extensions: {
    code: string
    stacktrace: string[]
    originalError?: GraphQLValidationError
  }
}
