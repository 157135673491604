import { defineStore } from 'pinia'

export const useAdminTokenStore = defineStore('adminToken', () => {
  const overlayedUserToken = ref('')
  const isConnectedAsUser = ref(false)
  const adminId = ref('')

  function setAdminId(uid: string): void {
    adminId.value = uid
  }

  return {
    overlayedUserToken,
    isConnectedAsUser,
    adminId,
    setAdminId,
  }
})
