export const primaryColor = '#096DD9'
export const secondaryColor = '#16A34A'
export const tertiaryColor = '#FACC15'
export const orangeColor = '#F97316'
export const redColor = '#EF4444'
export const grayColor = '#9A9A98'
export const purpleColor = '#7C3AED'
export const darkBlueColor = '#164E63'

export const colors = [primaryColor, secondaryColor, tertiaryColor, orangeColor, redColor, grayColor, purpleColor, darkBlueColor]

export const tagColors = [
  { bgClass: 'bg-purple-50', textClass: 'text-purple-700' },
  { bgClass: 'bg-blue-50', textClass: 'text-blue-700' },
  { bgClass: 'bg-red-50', textClass: 'text-red-700' },
  { bgClass: 'bg-green-50', textClass: 'text-green-700' },
  { bgClass: 'bg-yellow-50', textClass: 'text-yellow-700' },
]

export function getColorFromString(str: string) {
  if (!str || typeof str !== 'string')
    return colors[0]

  let sum = 0
  for (let i = 0; i < str.length; i++)
    sum += str.charCodeAt(i)

  return colors[sum % colors.length]
}

export function generateTagStyleFromString(str: string): string {
  if (!str)
    return ''

  let sum = 0
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i)
  }

  const tagColor = tagColors[sum % tagColors.length]
  return `${tagColor.bgClass} ${tagColor.textClass}`
}
