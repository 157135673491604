import { gql } from '@apollo/client/core'

export const WASTE_SERVICES_CONTAINERS_SUM_QUANTITY = gql`
  query WasteServicesContainersSumQuantity {
    waste_service_raw_materials_containers_aggregate {
      aggregate {
        sum {
          quantity
        }
      }
    }
  }
`

export const WASTE_SERVICES_CONTAINERS_SUM_CO2 = gql`
  query WasteServicesContainersSumCO2 {
    waste_service_aggregate {
      aggregate {
        sum {
          co2_amount
        }
      }
    }
  }
`
