import type { Maybe } from '~/types/graphql-backend-types/gql-types'

export function getPricesWithoutVTA(price: number, quantity: Maybe<number> | undefined): number {
  if (quantity) {
    const priceWithoutVAT = price * quantity
    if (Number.isNaN(priceWithoutVAT))
      return 0
    return priceWithoutVAT
  }
  return 0
}

export function getPricesWithVTA(price: number, quantity: number, vta: number) {
  const priceWithVAT = price * quantity * (Number.parseFloat(`1.${vta}`))

  if (Number.isNaN(priceWithVAT))
    return 0
  return priceWithVAT
}

export function getPricesWithVTAAndDiscount(price: number, quantity: number, vta: number, discount: number) {
  const priceWithVAT = price * quantity * (Number.parseFloat(`1.${vta}`))
  const priceWithVATAndDiscount = priceWithVAT * (Number.parseFloat(`1.${discount}`))
  if (Number.isNaN(priceWithVATAndDiscount))
    return 0
  return priceWithVATAndDiscount
}
