<script setup lang="ts">
import {
  Box,
  CircleCheckIcon,
  MessageSquareMore,
  Truck,
} from 'lucide-vue-next'
import type { CollectGraphql, CollectStatus } from '~/types/graphql-backend-types/gql-types'
import { statusColor } from '~/utils/status'

const router = useRouter()
const { t } = useI18n()
const { loadCollects } = useCollectsStore()
const { getCollects, collectsCount, filters, page, collectsLoading } = storeToRefs(useCollectsStore())

const selectedStatus = ref<CollectStatus>('' as CollectStatus)
const statusModalStatus = ref(false)
const dialog = ref<HTMLDialogElement>() as Ref<HTMLDialogElement>
const id = ref('')

const columns = [
  { key: 'type_id', label: t('global.category'), thClass: 'text-start' },
  { key: 'signedUrl', label: t('global.photo') },
  { key: 'container', label: t('global.content') },
  { key: 'producer', label: t('global.client'), thClass: 'text-center justify-center' },
  { key: 'endsAt', label: t('global.collect_date'), thClass: 'text-center justify-center' },
  { key: 'status', label: t('global.status'), thClass: 'text-end' },
]

function goToCollect(id: any) {
  const to = id || 'new'
  router.push(`/collects/${to}`)
}

function getIconForTypeId(typeId: string) {
  switch (typeId) {
    case 'bin_collection':
      return Truck
    default:
      return MessageSquareMore
  }
}

function formatText(text: any) {
  text = text.charAt(0).toUpperCase() + text.slice(1)
  return text.replace(/_/g, ' ')
}

function editCollect(id = '') {
  if (!id)
    return
  goToCollect(id)
}

function openModal(modalId: string, status: CollectStatus) {
  id.value = modalId
  selectedStatus.value = status
  statusModalStatus.value = true
  dialog.value?.showModal()
}

onMounted(async () => {
  await loadCollects()
  dialog.value = document.getElementById('status_modal') as HTMLDialogElement
})
</script>

<template>
  <div class="flex flex-col grow h-[calc(100%-84px)]">
    <!-- Search and filters -->
    <div class="z-2 flex-shrink-0 flex flex-col items-start gap-2 pl-4 md:flex-row md:items-center">
      <div class="w-full flex lg:min-w-80 lg:w-1/4">
        <OSearchBar v-model="filters.search" />
      </div>
      <div class="flex flex-col gap-2 md:flex-row">
        <RecollectionSortingPopOver v-model="filters.sort" class="h-8.5" />
        <RecollectionStatusPopOver v-model="filters.statuses" class="h-8.5" />
        <RecollectionClientPopOver v-model="filters.client" class="h-8.5" />
      </div>
    </div>

    <!-- Scrollable grid container -->
    <div class="flex-grow overflow-hidden md:h-56">
      <div v-if="!collectsLoading" class="h-full overflow-auto pt-2 md:pt-4">
        <div class="mx-auto max-w-[1500px] min-h-full w-full gap-2 rounded-md p-4 md:min-w-[500px] md:px-6 md:pb-6 bg-base-200">
          <ETable
            :columns="columns"
            :rows="getCollects"
            @row-selected="editCollect($event.id)"
          >
            <template #cell-type_id>
              <div class="col-span-2 w-full flex items-center justify-start gap-2">
                <div class="rounded-full bg-neutral p-1 text-neutral-content">
                  <component :is="getIconForTypeId('bin_collection')" :size="18" />
                </div>
                <p class="whitespace-nowrap">
                  {{ formatText('bin_collection') }}
                </p>
              </div>
            </template>

            <template #cell-signedUrl="{ row }: { row: CollectGraphql }">
              <div v-if="row?.collectedContainers?.collection?.length > 0" class="flex items-center gap-1">
                <div v-for="(container, index) in row.collectedContainers.collection" :key="index">
                  <div class="tooltip" :data-tip="container?.material?.name">
                    <ImageWrapper
                      v-if="container?.material?.documents?.collection[0]?.signedUrl"
                      size="w-8"
                      :src="container?.material.documents.collection[0]?.signedUrl"
                      :alt="container?.material.name"
                    />
                  </div>
                </div>
              </div>
            </template>

            <template #cell-container="{ row }: { row: CollectGraphql }">
              <template v-if="row?.collectedContainers?.collection?.length > 0">
                <div class="w-full flex items-center justify-start gap-1.5">
                  <div v-for="(container, index) in row.collectedContainers.collection" :key="index">
                    <div
                      v-if="index <= 0"
                      class="grid grid-flow-col w-fit items-center justify-start gap-1 rounded-lg bg-neutral text-neutral-content p-1 pr-1.5"
                    >
                      <p class="truncate text-neutral-content">
                        {{ container?.material.name }}
                      </p>
                    </div>
                    <div v-if="row.collectedContainers.collection.length > 1 && index === 1">
                      <p class="text-base-content/80">
                        +{{ row.collectedContainers.collection.length - 1 }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="flex items-center justify-center text-base-content italic">
                  {{ t("global.no_materials") }}
                </p>
              </template>
            </template>

            <template #cell-producer="{ row }: { row: CollectGraphql }">
              <div class="w-full justify-center">
                <p class="line-clamp-1 text-center text-base-content">
                  {{ row.client.name }}
                </p>
              </div>
            </template>
            <template #cell-endsAt="{ row }: { row: CollectGraphql }">
              <div class="w-full flex items-center justify-center text-base-content">
                {{
                  new Date(row.endsAt).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })
                }}
              </div>
            </template>
            <template #cell-status="{ row }: { row: CollectGraphql }">
              <div class="w-full flex items-center justify-end gap-1">
                <Tag
                  :tag="$t(`collect.status_${row.status}`)"
                  :tag-classes="statusColor.find((status) => status.status === row.status)"
                />
                <button
                  class="btn btn-sm btn-square btn-neutral transition-all"
                  @click.stop.prevent="openModal(row.id, row.status)"
                >
                  <CircleCheckIcon :size="14" />
                </button>
              </div>
            </template>
          </ETable>
        </div>
      </div>
      <div v-else class="flex grow items-center justify-center">
        <Loader class="mb-2 h-8 w-8 text-gray-500" />
      </div>
    </div>

    <!-- Pagination at the bottom -->
    <div class="flex-shrink-0 mt-2 mb-2">
      <EPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
    </div>
  </div>
  <StatusModal
    id="status_modal"
    v-model="statusModalStatus"
    :collect-id="id"
    :state="selectedStatus"
    size="lg"
    @update:status="selectedStatus = $event"
  />
</template>
