import apolloClient from '~/composables/graphql'
import { useGqlMikro } from '~/composables/useGqlMikro'
import {
  BO_COLLECTS_QUERY_LIST,
  CANCEL_COLLECT,
  COLLECTS_QUERY_LIST,
  CREATE_COLLECT_QUERY,
  GET_REFERENTS,
  SIGN_ALL_BSDS,
  SIGN_BSD,
  UPDATE_COLLECT_QUERY,
  UPDATE_COLLECTED_CONTAINER,
} from '~/queries/collects'
import {
  WASTE_SERVICES_CONTAINERS_SUM_CO2,
  WASTE_SERVICES_CONTAINERS_SUM_QUANTITY,
} from '~/queries/services'
import type {
  BsdGraphql,
  CancelCollectInputGraphql,
  CollectGraphql,
  CollectGraphqlFilters,
  CollectsCollection,
  CollectStatus,
  CreateCollectGraphql,
  Maybe,
  Mutation,
  PartialUserGraphql,
  SignAllCollectBsDsInput,
  SignBsdInput,
  UpdateCollectedContainerInput,
  UpdateCollectInformationsGraphql,
} from '~/types/graphql-backend-types/gql-types'
import { AscDescGraphql, DocumentContext, DocumentType } from '~/types/graphql-backend-types/gql-types'

export interface MaterialContainerChoice {
  materialId: string
  containers: {
    id: string
    containerId: string
    documents: {
      previewUrls: string[]
      files: File[]
    }
  }[][]
}

export const useCollectsStore = defineStore('collects', () => {
  const { query, mutate } = useGqlMikro()
  const { getSelectedClient } = storeToRefs(useClientsStore())
  const { collect } = storeToRefs(useCollectStore())
  const { user, isAdmin, isRecycler } = storeToRefs(useUsersStore())
  const { uploadDocument, persistDocument } = useDocumentStore()
  const { addToast } = useToast()

  const collects = ref<CollectsCollection>({} as CollectsCollection)
  const referents = ref<PartialUserGraphql[]>([])
  const materialContainerChoiceBeforeCreation = ref<MaterialContainerChoice[]>([])

  const preCollectCreation: CreateCollectGraphql = reactive({
    siteId: '',
    notes: '',
    startsAt: '',
    endsAt: '',
    containers: [],
    referentUserId: '',
    clientId: '',
  })

  const page = ref<number>(1)
  const collectsLoading = ref<boolean>(false)
  const offset = computed(() => (page.value - 1) * 10)

  const messageErrorUpload = computed(() => {
    const { t } = useI18n()
    return t('collect.uploadError')
  })

  const referentName = ref<string>('')

  const filters = reactive({
    statuses: [] as CollectStatus[],
    sort: AscDescGraphql.Desc,
    client: [] as string[],
    search: '',
  })

  const isEmptyFilters = computed(() => {
    return (
      filters.statuses.length === 0
      && filters.client.length === 0
      && filters.search === ''
    )
  })

  watch(filters, async () => {
    page.value = 1
    if (!user.value)
      return
    if (isAdmin.value) {
      await loadBOCollects()
    }
    else {
      await loadCollects()
    }
  }, { deep: true })

  watch(page, async () => {
    if (!user.value) {
      return
    }
    if (isAdmin.value) {
      await loadBOCollects()
    }
    else {
      await loadCollects()
    }
  })

  const collectsCount = computed(() => collects.value.count)

  const getCollects: ComputedRef<CollectGraphql[] | []> = computed(() => {
    if (collects.value.collection) {
      return collects.value.collection as CollectGraphql[]
    }
    return []
  }) as ComputedRef<CollectGraphql[] | []>

  const getCollectsAsOptions = computed(() =>
    collects.value?.collection?.map(item => ({
      label: item?.__typename,
      value: item?.id,
    })),
  )

  // TODO : Need to change this ASAP
  async function loadBOCollects() {
    const recyclerProducerFilters: Partial<CollectGraphqlFilters> = {}
    const client = getSelectedClient.value
    if (client) {
      // @ts-expect-error __typename is a property of RecyclerGraphql or ProducerGraphql
      if (client.__typename === 'RecyclerGraphql') {
        recyclerProducerFilters.recyclerIds = [client.id]
      }
      // @ts-expect-error __typename is a property of RecyclerGraphql or ProducerGraphql
      if (client.__typename === 'ProducerGraphql') {
        recyclerProducerFilters.producerIds = [client.id]
      }
    }
    if (!user.value)
      return

    collectsLoading.value = true
    const { data, errors } = await query({
      query: BO_COLLECTS_QUERY_LIST,
      variables: {
        pagination: { limit: 10, offset: offset.value },
        filters: {
          ...recyclerProducerFilters,
          orderBy: { field: 'startsAt', order: filters.sort },
          status: filters.statuses.length > 0 ? filters.statuses : null,
        },
      },
    })

    if (data === null && errors) {
      throw new Error(errors[0].message)
    }

    collectsLoading.value = false
    collects.value = JSON.parse(JSON.stringify(data.collects))
    return data
  }

  async function loadCollects() {
    const recyclerProducerFilters: Partial<CollectGraphqlFilters> = {}

    if (!user.value)
      return

    // If user is a recycler he want to filter by producers
    if (isRecycler.value && filters.client.length > 0) {
      recyclerProducerFilters.producerIds = filters.client
    }
    else if (!isRecycler.value && filters.client.length > 0) {
      recyclerProducerFilters.recyclerIds = filters.client
    }

    collectsLoading.value = true

    const { data } = await query({
      query: COLLECTS_QUERY_LIST,
      variables: {
        pagination: { limit: 10, offset: offset.value },
        filters: {
          ...recyclerProducerFilters,
          orderBy: { field: 'startsAt', order: filters.sort },
          status: filters.statuses.length > 0 ? filters.statuses : null,
          search: filters.search ?? null,
        },
      },
    })

    collectsLoading.value = false
    collects.value = JSON.parse(JSON.stringify(data.collects))
    return data
  }

  function formatContainerData(materials: MaterialContainerChoice[], addDocuments: boolean = false): { materialId: string, containerId: string, files?: File[] }[] {
    return materials.map((materials) => {
      return materials.containers.flat().map((container) => {
        const data = { materialId: materials.materialId, containerId: container.containerId }

        if (addDocuments) {
          data.files = container.documents.files
        }
        return data
      }).flat()
    }).flat()
  }

  async function createCollect(): Promise<{ errors?: readonly any[], data?: CollectGraphql }> {
    const { data, errors } = await mutate({
      mutation: CREATE_COLLECT_QUERY,
      variables: {
        input: {
          ...preCollectCreation,
          containers: formatContainerData(materialContainerChoiceBeforeCreation.value),
          clientId: user.value.client.id,
        },
      },
    })

    if (errors && errors.length > 0) {
      return { errors }
    }

    // * Upload collected container documents
    await uploadCollectedContainerDocuments(formatContainerData(materialContainerChoiceBeforeCreation.value, true), data?.createCollect)

    resetPrecollectCreation()

    return { data: data?.createCollect }
  }

  async function uploadCollectedContainerDocuments(
    collectedContainers: { materialId: string, containerId: string, files: File[], uploaded?: boolean }[],
    createdCollect: CollectGraphql,
  ): Promise<void> {
    for (const cc of createdCollect?.collectedContainers.collection) {
      const collectedContainerId = cc?.id as string
      const ccMaterialId = cc?.material.id
      const ccContainerId = cc?.container?.id

      const collectedContainer = collectedContainers.find((c) => {
        return c.materialId === ccMaterialId
          && c.containerId === ccContainerId && !('uploaded' in c)
      })
      const { files } = collectedContainer!

      if (!files)
        continue

      for (const file of files) {
        try {
          await uploadDocument({ file })

          const persistDocumentPayload = {
            file,
            clientId: user.value.client.id,
            context: {
              id: collectedContainerId,
              type: DocumentContext.CollectedContainer,
            },
            type: DocumentType.Picture,
          }

          await persistDocument(persistDocumentPayload)
        }
        catch {
          addToast('', { type: 'error', message: messageErrorUpload.value })
        }
      }
      if (collectedContainer) {
        collectedContainer.uploaded = true
      }
    }
  }
  async function updateCollectInformations(payload: UpdateCollectInformationsGraphql): Promise<Mutation['updateCollectInformations']> {
    const { data, errors } = await mutate({
      mutation: UPDATE_COLLECT_QUERY,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    collect.value.status = data!.updateCollectInformations.status
    collect.value.startsAt = data!.updateCollectInformations.startsAt
    collect.value.transportPrice = data!.updateCollectInformations.transportPrice
    collect.value.licensePlate = data!.updateCollectInformations.licensePlate

    return data!.updateCollectInformations
  }

  async function updateCollectStatus(payload: { collectId: string, status: CollectStatus }): Promise<Mutation['updateCollectInformations']> {
    const { data, errors } = await mutate({
      mutation: UPDATE_COLLECT_QUERY,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    collect.value.status = data!.updateCollectInformations.status

    findAndUpdateCollectStatus(payload.collectId, data!.updateCollectInformations.status)
    return data!.updateCollectInformations
  }

  function findAndUpdateCollectStatus(collectId: string, status: CollectStatus) {
    const coco = collects.value.collection.find(item => item?.id === collectId)
    if (!coco)
      return
    coco.status = status
  }

  async function updateCollectedContainer(payload: UpdateCollectedContainerInput) {
    const { data, errors } = await mutate({
      mutation: UPDATE_COLLECTED_CONTAINER,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    const c = collect.value.collectedContainers.collection.find(item => item?.id === payload.collectedContainerId)
    if (!c)
      return null
    c.quantity = data?.updateCollectedContainer.quantity
    c.treatmentCode = data?.updateCollectedContainer.treatmentCode

    return data?.updateCollectedContainer
  }

  function resetPrecollectCreation() {
    preCollectCreation.clientId = user.value.client.id
    preCollectCreation.siteId = ''
    preCollectCreation.referentUserId = ''
    preCollectCreation.containers = []
    materialContainerChoiceBeforeCreation.value = []
    preCollectCreation.notes = ''
    preCollectCreation.startsAt = ''
    preCollectCreation.endsAt = ''
    referentName.value = ''
  }

  async function getReferents() {
    const { data, errors } = await query({
      query: GET_REFERENTS,
    })

    if (data === null && errors) {
      throw new Error(errors[0].message)
    }

    referents.value = data.referents.collection as PartialUserGraphql[]
  }

  function getServiceById(id: string): Maybe<CollectGraphql> | undefined {
    return collects.value.collection.find(item => item?.id.toString() === id.toString())
  }

  function updateBSD(payload: { collectId: string, BSD: BsdGraphql }) {
    const { BSD, collectId } = payload
    const collect = collects.value.collection.find(item => item?.id.toString() === collectId.toString())
    collect.BSDs.collection[0] = BSD
  }

  async function cancelCollect(id: string): Promise<Mutation['cancelCollect']> {
    const { data, errors } = await mutate({
      mutation: CANCEL_COLLECT,
      variables: { input: { collectId: id } as CancelCollectInputGraphql },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    const collect = collects.value.collection.find(item => item?.id === id)

    if (!collect)
      throw new Error('Collect not found')

    collect.status = data!.cancelCollect.status

    return data!.cancelCollect
  }

  async function getSumOfWasteQuantity() {
    const { data } = await apolloClient.query({ query: WASTE_SERVICES_CONTAINERS_SUM_QUANTITY })
    return data.value.waste_service_raw_materials_containers_aggregate.aggregate.sum.quantity
  }

  async function getSumOfCo2() {
    const { data } = await apolloClient.query({ query: WASTE_SERVICES_CONTAINERS_SUM_CO2 })
    return data.value.waste_service_aggregate.aggregate.sum.co2_amount
  }

  async function signBSD(collectId: string, collectedContainerIds: string) {
    const { mutate } = useGqlMikro()
    const input: SignBsdInput = {
      collectedContainerIds,
      collectId,
      signatureCode: 1,
    }

    const { data, errors } = await mutate({
      mutation: SIGN_BSD,
      variables: {
        input,
      },
    })
    return { data, errors }
  }

  async function signAllBSDs(collectId: string) {
    const { mutate } = useGqlMikro()
    const input: SignAllCollectBsDsInput = {
      collectId,
      signatureCode: 1,
    }

    const { data, errors } = await mutate({
      mutation: SIGN_ALL_BSDS,
      variables: {
        input,
      },
    })
    return { data, errors }
  }

  function resetFilters() {
    filters.statuses = []
    filters.sort = AscDescGraphql.Asc
    filters.client = []
    filters.search = ''
  }

  return {
    collects,
    referents,
    isEmptyFilters,
    offset,
    preCollectCreation,
    materialContainerChoiceBeforeCreation,
    filters,
    page,
    collectsLoading,
    collectsCount,
    getCollects,
    referentName,
    getCollectsAsOptions,
    createCollect,
    updateCollectInformations,
    updateCollectStatus,
    updateCollectedContainer,
    loadCollects,
    loadBOCollects,
    resetFilters,
    getServiceById,
    updateBSD,
    cancelCollect,
    getSumOfWasteQuantity,
    getReferents,
    getSumOfCo2,
    signBSD,
    signAllBSDs,
    formatContainerData,
    resetPrecollectCreation,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCollectsStore, import.meta.hot))
}
