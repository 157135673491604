<script setup lang="ts">
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  numberPage: {
    type: Number,
    required: true,
    default: 1,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
}>()

const { t } = useI18n()

function goToPagination(page: number | string) {
  if (page === '...')
    return
  if (page !== props.modelValue)
    emit('update:modelValue', page as number)
}

const paginationList = computed(() => {
  const pagination = []
  const numberPage = props.numberPage
  const modelValue = props.modelValue

  if (numberPage <= 7) {
    for (let i = 1; i <= numberPage; i++)
      pagination.push(i)
  }
  else {
    if (modelValue <= 4) {
      for (let i = 1; i <= 5; i++)
        pagination.push(i)
      pagination.push('...')
      pagination.push(numberPage)
    }
    else if (modelValue >= numberPage - 3) {
      pagination.push(1)
      pagination.push('...')
      for (let i = numberPage - 4; i <= numberPage; i++)
        pagination.push(i)
    }
    else {
      pagination.push(1)
      pagination.push('...')
      for (let i = modelValue - 1; i <= modelValue + 1; i++)
        pagination.push(i)
      pagination.push('...')
      pagination.push(numberPage)
    }
  }
  return pagination
})

function updatePagination(action: string) {
  switch (action) {
    case 'previous':
      if (props.modelValue !== 1)
        emit('update:modelValue', props.modelValue - 1)
      break
    case 'next':
      if (props.modelValue !== props.numberPage)
        emit('update:modelValue', props.modelValue + 1)
      break
    default:
      break
  }
}
</script>

<template>
  <nav v-if="paginationList.length > 0" :aria-label="t('global.table.nav')" class="flex justify-center">
    <div class="join">
      <!-- Previous button -->
      <button
        class="join-item btn btn-sm btn-outline btn-secondary"
        :class="props.modelValue === 1 ? 'btn-disabled' : ''"
        @click="updatePagination('previous')"
      >
        <ChevronLeft :size="16" />
      </button>

      <!-- Page numbers -->
      <template v-for="index in paginationList" :key="index">
        <button
          class="join-item btn btn-sm btn-outline btn-secondary"
          :class="{
            'btn-active btn-primary': props.modelValue === index,
            'btn-disabled': index === '...',
          }"
          @click="goToPagination(index)"
        >
          {{ index }}
        </button>
      </template>

      <!-- Next button -->
      <button
        class="join-item btn btn-sm btn-outline btn-secondary"
        :class="props.modelValue === props.numberPage || props.numberPage === 0 ? 'btn-disabled' : ''"
        @click="updatePagination('next')"
      >
        <ChevronRight :size="16" />
      </button>
    </div>
  </nav>
</template>
