export const isCrispChatOpen = ref<boolean>(false)

export function toggleCrispChat() {
  if (isCrispChatOpen.value) {
    window.$crisp.push(['do', 'chat:hide'])
    window.$crisp.push(['do', 'chat:close'])
    isCrispChatOpen.value = false
  }
  else {
    window.$crisp.push(['do', 'chat:show'])
    window.$crisp.push(['do', 'chat:open'])
    isCrispChatOpen.value = true
  }
}

export function hideCrispChat() {
  window?.$crisp?.push(['do', 'chat:hide'])
  window?.$crisp?.push(['do', 'chat:close'])
  isCrispChatOpen.value = false
}
