import type { Unsubscribe } from 'firebase/auth'
import type { NavigationGuard } from 'vue-router'
import { fb_auth } from '~/services/firebase'

let unsubscribe: Unsubscribe | null = null

export const authGuard: NavigationGuard = async (to, from, next) => {
  const { isAdmin, user } = storeToRefs(useUsersStore())
  const { getCurrentUser } = useUsersStore()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const query = Object.fromEntries(urlSearchParams.entries())

  // Clean up previous listener if it exists
  if (unsubscribe) {
    unsubscribe()
    unsubscribe = null
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // Need to authenticate in FB first then check if admin
    fb_auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        // Need to check if session is expired then request refresh token
        if (!user.value) {
          await getCurrentUser()
        }

        if (!isAdmin.value) {
          next({
            path: '/auth/login',
            query,
          })
        }
        else {
          next()
        }
      }
    })
  }

  else if (to.matched.some(record => record.meta.requiresAuth)) {
    let authCheckComplete = false

    unsubscribe = fb_auth.onAuthStateChanged((user) => {
      if (!authCheckComplete) {
        authCheckComplete = true
        if (user) {
          next()
        }
        else {
          next({
            path: '/auth/login',
            query,
          })
        }
      }
    })

    // Set a timeout to ensure navigation occurs even if Firebase is slow
    setTimeout(() => {
      if (!authCheckComplete) {
        authCheckComplete = true
        next({
          path: '/auth/login',
          query,
        })
      }
    }, 5000) // 5 second timeout
  }

  else {
    next()
  }
}
