import { gql } from '@apollo/client/core'

export const DECLARE_INCIDENT_ON_COLLECT = gql`
mutation DeclareIncidentOnCollect($input: DeclareIncidentGraphqlInput!) {
  declareIncidentOnCollect(input: $input) {
    status
    createdAt
    id
    comment
    type
    penaltyAmount
    penaltyComment
    createdByUser {
      firstName
      lastName
    }
    documents {
      collection {
        name
        id
        signedUrl
      }
    }
  }
}
`

export const UPDATE_INCIDENT_ON_COLLECT = gql`
mutation UpdateIncident($input: UpdateIncidentGraphqlInput!) {
  updateIncident(input: $input) {
    comment
    id
    penaltyAmount
    penaltyComment
    type
    updatedAt
    createdAt
    documents {
      collection {
        signedUrl
        type
      }
    }
  }
}
`

export const GET_INCIDENTS = gql`
query Incidents($pagination: PaginationArgs, $filters: IncidentFiltersGraphql!) {
  incidents(pagination: $pagination, filters: $filters) {
    count
    collection {
      documents {
        collection {
          name
          signedUrl
          type
          id
        }
      }
      id
      penaltyAmount
      penaltyComment
      type
      updatedAt
      createdAt
      comment
      status
      createdByUser {
        id
        firstName
        function
        email
        client {
          id
        }
      }
    }
  }
}
`
// HASURA
export const INCIDENTS_QUERY_LIST = gql`
  query Incidents {
    incidents {
      id
      incident_status
      incident_type
      penalty_amount
      comments
      penalty_comment
      waste_service_id
      photo_url
      _created_at
      _created_by
      waste_service_id
      waste_service {
      wr_client_id
      wp_client_id
    }
    }
  }
`

export const INSERT_INCIDENT = gql`
  mutation InsertIncident($object: incidents_insert_input!) {
    insert_incidents_one(object: $object) {
      waste_service_id
      incident_type
      incident_status
      comments
      id
      photo_url
      penalty_amount
      _created_at
      _created_by
    }
  }
`

export const DELETE_INCIDENT = gql`
  mutation DeleteIncident($id: Int!) {
    delete_incidents_by_pk(id: $id) {
      id
    }
  }
`

export const UPDATE_INCIDENT = gql`
  mutation UpdateIncident($id: Int!, $object: incidents_set_input!) {
    update_incidents_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
