<script setup lang="ts">
import type { PropType } from 'vue'
import {
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue'
import { BoxIcon, ChevronDownIcon } from 'lucide-vue-next'
import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRefs,
} from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  options: {
    type: Array as PropType<any[]>,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  prefixIcon: {
    type: Function,
  },
  label: {
    type: String,
  },
  suffixIcon: {
    type: Function,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  keyForLabel: {
    type: String,
    default: 'label',
  },
  outerClasses: {
    type: String,
    default: '',
  },
  keyForValue: {
    type: String,
    default: 'value',
  },
  showDocument: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: null,
  },
  allowDisableField: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['change', 'input'])
const { t } = useI18n()
const model = defineModel()
const { keyForLabel, keyForValue, id, disabled, options, error, allowDisableField } = toRefs(props)
const isOpen = ref(false)
const selectedOption = ref<any>(null)

const buttonRef = ref<HTMLElement | null>(null)
const listRef = ref<HTMLElement | null>(null)

const { floatingStyles } = useFloating(buttonRef, listRef, {
  placement: 'bottom-start',
  middleware: [offset(5), flip(), shift()],
})

const containerClasses = computed(() => [
  'relative flex items-center rounded-lg bg-base-100',
])

const inputClasses = computed(() => [
  'w-full px-3 py-1 h-8 text-sm text-base-content rounded-lg',
  'bg-transparent border border-neutral focus:outline-none focus:ring-0',
  'flex justify-between items-center',
  { 'cursor-not-allowed': disabled.value },
  {
    'border-red-500': error.value,
    'border-green-500': error.value !== null && model.value,
    'opacity-60 cursor-not-allowed !bg-base-200': disabled.value && allowDisableField.value,
  },
])

const formattedLabel = computed(() => {
  return selectedOption.value ? getOptionLabel(selectedOption.value) : getOptionLabel(model.value)
})

const formatPlaceholder = computed(() => {
  return options.value.length > 0 ? props.placeholder : t('select.no_options')
})

function toggleDropdown() {
  if (disabled.value)
    return
  isOpen.value = !isOpen.value
}

function closeDropdown() {
  isOpen.value = false
}

function getOptionLabel(option: any): string {
  if (typeof option === 'object' && option !== null) {
    return option[keyForLabel.value]
  }
  const opt = options.value.find(opt => getOptionValue(opt) === option)
  if (opt)
    return opt[keyForLabel.value] || String(opt)
  return String(option)
}

function getOptionValue(option: any): string {
  if (typeof option === 'object' && option !== null) {
    return option[keyForValue.value]
  }
  return String(option)
}

function isOptionDisabled(option: any): boolean {
  return allowDisableField.value && option.disabled
}

function selectOption(option: any) {
  if (isOptionDisabled(option) || disabled.value)
    return
  model.value = typeof option === 'object' && option !== null ? option[keyForValue.value] : option
  selectedOption.value = option
  isOpen.value = false
  emit('change', model.value)
}

onMounted(() => {
  nextTick(() => {
    if (model.value || model.value === 0) {
      // @ts-expects-error double equal is needed for conversion id of type number to model.value of type string
      selectedOption.value = options.value.find(option => getOptionValue(option) == model.value)
      emit('input', model.value)
    }
  })
})
</script>

<template>
  <div v-click-outside="closeDropdown" class="relative w-full" :class="outerClasses">
    <label :for="id" class="label">
      <span class="label-text">{{ label }}</span>
    </label>
    <div :class="containerClasses">
      <button
        ref="buttonRef"
        class="text-left focus:border-primary focus:ring-primary focus:ring-1 text-base-content"
        :class="[inputClasses, { disabled: (disabled && allowDisableField) || options.length === 0 }]"
        v-bind="$attrs"
        type="button"
        @click="toggleDropdown"
      >
        <div class="flex grow text-base-content space-x-2">
          <slot>
            <component :is="prefixIcon" v-if="prefixIcon" :size="18" />
            <div v-if="showDocument" class="flex items-center">
              <img v-if="selectedOption?.documents?.collection[0]?.signedUrl" :src="selectedOption.documents.collection[0].signedUrl" alt="document" class="h-5 w-5 rounded-lg">
              <BoxIcon v-else :size="18" />
            </div>
            <span v-if="selectedOption" class="text-smpl-2">{{ formattedLabel }}</span>
            <span v-else class="text-sm text-base-content/70 pl-2">{{ formatPlaceholder }}</span>
          </slot>
        </div>
        <ChevronDownIcon :size="16" :class="{ 'transform rotate-180': isOpen }" />
      </button>
    </div>
    <ul
      v-if="isOpen && options.length > 0"
      ref="listRef"
      :style="floatingStyles"
      class="z-10 w-full my-1 overflow-auto bg-base-100 border border-neutral rounded-lg shadow-lg max-h-60 focus:outline-none"
    >
      <li
        v-for="option in options"
        :key="option[keyForValue]"
        class="flex items-center text-sm text-base-content px-4 py-2 cursor-pointer hover:bg-primary/20"
        :class="{ 'opacity-50 pointer-events-none bg-base-200': isOptionDisabled(option) }"
        @click="selectOption(option)"
      >
        <slot name="option" :option="option">
          <div class="space-x-2 flex grow">
            <div v-if="showDocument">
              <img v-if="option?.documents?.collection[0]?.signedUrl" :src="option.documents.collection[0].signedUrl" alt="document" class="h-5 w-5 rounded-lg">
              <BoxIcon v-else :size="18" />
            </div>
            <span>{{ getOptionLabel(option) }}</span>
            <div class="flex grow justify-end">
              <slot name="additional-info" :option="option" />
            </div>
          </div>
        </slot>
      </li>
    </ul>
    <label v-if="error" class="label">
      <span class="label-text-alt text-error">{{ error }}</span>
    </label>
  </div>
</template>

<style scoped>
.e_select.disabled {
  @apply bg-base-200 cursor-not-allowed pointer-events-none placeholder:text-base-content/70;
}
</style>
