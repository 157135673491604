<script setup lang="ts">
/**
 * @modelValue : Number
 * (The selected Tab)
 *
 * @listTabs : Array<String>
 * (This list will render the defined tabs, don't forget to add localisation)
 *
 * @tabName : String
 * (The name of the query param to use for the tab)
 */

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  listTabs: {
    type: Array<string>,
    required: true,
    default: [] as any[],
  },
  tabName: {
    type: String,
    required: false,
    default: 'tab',
  },
  disabledTabs: {
    type: Array<number>,
    required: false,
    default: [] as any[],
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
  (e: 'click', val: number): void
}>()

const route = useRoute()

function setCurrentTab(index: number) {
  emit('click', index)
  if (props.disabledTabs.includes(index))
    return

  emit('update:modelValue', index)
}

function init() {
  const queries = route.query
  emit('update:modelValue', queries[props.tabName] ? Number.parseInt(queries[props.tabName] as string) : 0)
}

onMounted(init)
</script>

<template>
  <div role="tablist" class="tabs tabs-bordered">
    <template v-for="(tab, index) in props.listTabs" :key="index">
      <a
        role="tab"
        class="tab tab-primary [--tab-border-color:primary]"
        :class="[{ 'tab-active': props.modelValue === index }, { 'tab-disabled': props.disabledTabs.includes(index) }]"
        @click="setCurrentTab(index)"
      >
        <slot :name="index" :tab="tab">
          {{ tab }}
        </slot>
      </a>
    </template>
  </div>
</template>
