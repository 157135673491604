import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { omitBy } from 'lodash'
import { useGqlMikro } from '~/composables/useGqlMikro'
import type {
  ClientFilterGraphql,
  ClientGraphql,
  ClientInput,
  CreateClientInput,
  Maybe,
  ProducerGraphql,
  ProducersToRecyclerInput,
  QueryClientsArgs,
  RecyclerGraphql,
  RecyclersToProducerInput,
} from '~/gql-types/gql-types'
import {
  GET_CLIENT,
  GET_CLIENTS,
  GET_CLIENTS_FOR_SELECT,
  UPDATE_CLIENT,
} from '~/queries/client'
import {
  ADD_PRODUCERS_TO_RECYCLER,
  ADD_RECYCLERS_TO_PRODUCER,
  CREATE_CLIENT,
  GET_OVERLAYED_CLIENT_TOKEN,
  REMOVE_PRODUCERS_FROM_RECYCLER,
  REMOVE_RECYCLERS_FROM_PRODUCER,
} from '~/queries/clients'

export const useClientsStore = defineStore('clients', () => {
  const { query, mutate } = useGqlMikro()
  const { overlayedUserToken, isConnectedAsUser } = storeToRefs(useAdminTokenStore())

  const client = ref<RecyclerGraphql | ProducerGraphql>({} as RecyclerGraphql | ProducerGraphql)
  const clients = ref<ClientGraphql[]>([])
  const selectedClientId = ref<number | string>(0)
  const clientsCount = ref()
  const clientsForBOSelect = ref<ClientGraphql[]>([])
  const clientsLoading = ref(false)

  const page = ref<number>(1)
  const offset = computed(() => (page.value - 1) * 10)

  const filters = reactive({
    search: '',
  })

  watch(page, async () => {
    await fetchClients({})
  })

  watch(filters, async () => {
    await fetchClients({})
  }, { deep: true })

  // Only usable for BO
  const getSelectedClient = computed(() => {
    const client = clientsForBOSelect.value.find(c => Number(c.id) === Number(selectedClientId.value))
    return client
  })

  // @ts-expect-error recyclers and producers exists on Producer/Recycler Graphql
  const getRecyclers: ComputedRef<RecyclerGraphql[]> = computed(() => clientsForBOSelect.value.filter(c => c.__typename === 'RecyclerGraphql') ?? [])
  // @ts-expect-error recyclers and producers exists on Producer/Recycler Graphql
  const getProducers: ComputedRef<ProducerGraphql[]> = computed(() => clientsForBOSelect.value.filter(c => c.__typename === 'ProducerGraphql') ?? [])

  const isRecycler = computed(() => {
    return client.value?.__typename === 'RecyclerGraphql'
  })

  const isProducer = computed(() => {
    return client.value?.__typename === 'ProducerGraphql'
  })

  const clientSites = computed(() => {
    return client.value?.sites?.collection ?? []
  })

  function getClientById(clientId: string) {
    return clients.value.find((c: Maybe<ClientGraphql>) => c && Number(c.id) === Number(clientId))
  }

  const getClientRecyclersOrProducers = computed(() => {
    // @ts-expect-error recyclers and producers exists on Producer/Recycler Graphql
    return isRecycler.value ? client.value?.producers.collection ?? [] : client.value?.recyclers?.collection ?? []
  })

  async function setCurrentClient(clientId: string) {
    await fetchClient({ clientIds: [clientId] })
  }

  async function createClient(input: CreateClientInput): Promise<ClientGraphql> {
    const { mutate } = useGqlMikro()

    const { data, errors, validationError } = await mutate({
      mutation: CREATE_CLIENT,
      variables: { input },
    })

    if (validationError) {
      throw new Error(validationError)
    }

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    clients.value.push(data?.createClient as ClientGraphql)

    return data?.createClient as ClientGraphql
  }

  async function fetchClients(f: ClientFilterGraphql = {}): Promise<void> {
    clientsLoading.value = true
    const { clientIds } = f
    const clientFilters: ClientFilterGraphql = { search: null }

    if (filters.search)
      clientFilters.search = filters.search
    if (clientIds && !clientIds.includes('0'))
      clientFilters.clientIds = clientIds

    const { data } = await query({
      query: GET_CLIENTS,
      variables: {
        pagination: { limit: 10, offset: offset.value },
        filters: clientFilters,
      } as QueryClientsArgs,
    })

    clientsLoading.value = false
    clientsCount.value = data?.clients.count
    // ! Without this, if froze the array, apollo caching is freezing the data
    clients.value = JSON.parse(JSON.stringify(data?.clients?.collection)) as ClientGraphql[] || []
  }

  async function fetchClient(f: ClientFilterGraphql = {}): Promise<void> {
    const { clientIds } = f
    const clientFilters: ClientFilterGraphql = { search: null }

    if (clientIds && !clientIds.includes('0'))
      clientFilters.clientIds = clientIds

    const { data } = await query({
      query: GET_CLIENT,
      variables: {
        pagination: { limit: 10, offset: offset.value },
        filters: clientFilters,
      } as QueryClientsArgs,
    })

    // ! Without this, if froze the array, apollo caching is freezing the data
    client.value = JSON.parse(JSON.stringify(data?.clients?.collection[0])) as ProducerGraphql | RecyclerGraphql
  }

  async function getClientsForBOSelect(): Promise<ClientGraphql[]> {
    const { data, errors } = await query({
      query: GET_CLIENTS_FOR_SELECT,
      variables: {
        pagination: { limit: 1000, offset: 0 },
        filters: {},
      } as QueryClientsArgs,
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }
    clientsForBOSelect.value = JSON.parse(JSON.stringify(data.clients.collection as ClientGraphql[]))

    return data.clients.collection as ClientGraphql[] || []
  }

  async function updateClient(input: ClientInput): Promise<{ validationError: string | null | undefined }> {
    const inputData = omitBy(input, v => v === null)

    const { data, validationError } = await mutate({
      mutation: UPDATE_CLIENT,
      variables: { input: inputData },
    })

    if (data) {
      clients.value.splice(clients.value.findIndex(c => c.id === client.value.id), 1, data!.updateClient)
      client.value = data!.updateClient as RecyclerGraphql | ProducerGraphql
    }

    return { validationError }
  }

  async function addRecyclersToProducer(payload: RecyclersToProducerInput): Promise<void> {
    const { data, errors } = await mutate({
      mutation: ADD_RECYCLERS_TO_PRODUCER,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }
  }

  async function addProducersToRecycler(payload: ProducersToRecyclerInput): Promise<void> {
    const { data, errors } = await mutate({
      mutation: ADD_PRODUCERS_TO_RECYCLER,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }
  }

  async function removeRecyclersFromProducer(payload: RecyclersToProducerInput): Promise<void> {
    const { data, errors } = await mutate({
      mutation: REMOVE_RECYCLERS_FROM_PRODUCER,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }
  }

  async function removeProducersFromRecycler(payload: ProducersToRecyclerInput): Promise<void> {
    const { data, errors } = await mutate({
      mutation: REMOVE_PRODUCERS_FROM_RECYCLER,
      variables: {
        input: payload,
      },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }
  }

  async function stealUserIdentity(userIdToUsurpate: string): Promise<void> {
    const { data, errors } = await mutate({
      mutation: GET_OVERLAYED_CLIENT_TOKEN,
      variables: { input: { userId: userIdToUsurpate } },
    })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    signInWithCustomToken(getAuth(), data!.logAs!.userJWT).then((userCredential) => {
      overlayedUserToken.value = userCredential.user.accessToken
      isConnectedAsUser.value = !isConnectedAsUser.value
    })
  }

  return {
    clients,
    clientsLoading,
    page,
    selectedClientId,
    clientSites,
    getClientRecyclersOrProducers,
    getSelectedClient,
    clientsCount,
    clientsForBOSelect,
    getRecyclers,
    getProducers,
    filters,
    client,
    isRecycler,
    isProducer,
    getClientById,
    setCurrentClient,
    getClientsForBOSelect,
    fetchClients,
    fetchClient,
    createClient,
    updateClient,
    addProducersToRecycler,
    addRecyclersToProducer,
    removeProducersFromRecycler,
    removeRecyclersFromProducer,
    stealUserIdentity,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClientsStore, import.meta.hot))
}
