import { signInWithEmailAndPassword } from 'firebase/auth'
import { loadStores } from '~/controllers/stores'
import { fb_auth, on_auth_state_changed } from '~/services/firebase'

// Initialize as undefined or null instead of empty object
let unsubscribe: (() => void) | null = null

export async function loadUser(): Promise<any> {
  const { stores_loaded } = storeToRefs(useUsersStore())

  // Store the unsubscribe function returned by onAuthStateChanged
  unsubscribe = on_auth_state_changed(fb_auth, async (user: any) => {
    if (user) {
      await loadStores()
    }
    else {
      stores_loaded.value = true
    }
  })
}

export function getUserId() {
  const user = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ?? ''
    return userId
  }
  return ''
}

export async function signIn(email: string, password: string) {
  await signInWithEmailAndPassword(fb_auth, email, password)
  await loadUser()
}

export async function logout() {
  const { resetFilters } = useCollectsStore()
  resetFilters()

  // Check if unsubscribe exists before calling it
  if (unsubscribe) {
    unsubscribe()
  }

  await fb_auth.signOut()
}
