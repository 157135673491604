<script setup lang="ts">
import type { PropType } from 'vue'
import { BoxIcon, Calendar } from 'lucide-vue-next'
// @ts-expect-error no types for vue-star-rating
import StarRating from 'vue-star-rating'
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'
import { statusColor } from '~/utils/status'

const props = defineProps({
  collect: {
    type: Object as PropType<CollectGraphql>,
    required: true,
    default: null,
  },
})

const { isProducer } = storeToRefs(useUsersStore())

const { collect } = toRefs(props)

const collectedContainers = computed(() => {
  return collect.value.collectedContainers.collection
})

const isLoading = ref<boolean>(false)
const modalStatus = ref<boolean>(false)
const cancelCollectModal = ref<HTMLDialogElement>() as Ref<HTMLDialogElement>

const isEditingService = ref<boolean>(false)
const selectedStatus = ref<CollectStatus>() as Ref<CollectStatus>
const selectedReferent = ref<string>('')
const referentOptions = ref<string[]>([])
const notes = ref<string>('')

const path = useRoute().path

const router = useRouter()
const { t } = useI18n()

const startsAt = computed(() => {
  if (collect.value?.startsAt) {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    }).format(new Date(collect.value.startsAt))
  }
  return new Date()
})

const endsAt = computed(() => {
  if (collect.value?.endsAt) {
    return new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    }).format(new Date(collect.value.endsAt))
  }
  return new Date()
})

const collectMaterialsPrice = computed(() => {
  if (collect.value) {
    const activePricings = collectedContainers.value.reduce((acc, sum) => {
      if (sum?.material?.activePricing?.price) {
        acc += sum?.material?.activePricing?.price
      }
      return acc
    }, 0)
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(activePricings)
  }
  return 0
})

const collectedContainersNameSignedUrl = computed(() => {
  if (!collectedContainers.value)
    return []

  const containerMap = new Map()

  collectedContainers.value.forEach((container) => {
    const { id, name, documents } = container?.material || {}
    if (!id || !name)
      return

    const signedUrl = documents?.collection[0]?.signedUrl || null

    if (!containerMap.has(id)) {
      containerMap.set(id, { name, quantity: 1, signedUrl })
    }
    else {
      const existingContainer = containerMap.get(id)
      existingContainer.quantity += 1
      // Only update signedUrl if it's not set and the current container has one
      if (!existingContainer.signedUrl && signedUrl) {
        existingContainer.signedUrl = signedUrl
      }
    }
  })

  return Array.from(containerMap.values()).map(({ name, quantity, signedUrl }) => ({
    name: quantity === 1 ? name : `${name} x${quantity}`,
    signedUrl,
  }))
})

const collectedContainerPreviewDocuments = computed(() => {
  if (!collectedContainers.value)
    return []

  return collectedContainersNameSignedUrl.value.filter(container => container?.signedUrl)
})

function editService(id: any) {
  if (selectedStatus.value === CollectStatus.PickupConfirmed || selectedStatus.value === CollectStatus.Confirmed) {
    isEditingService.value = true
  }
  router.push(`/waste-collection/${id}`)
}

function openCancelCollectModal() {
  if (selectedStatus.value !== CollectStatus.WpCancel) {
    modalStatus.value = true
    cancelCollectModal.value.showModal()
  }
}

function navigateToCollect() {
  return path === '/' ? router.push(`/collects/${collect.value.id}`) : null
}

onMounted(async () => {
  selectedStatus.value = collect.value?.status
  cancelCollectModal.value = document.getElementById('cancel_collect_modal') as HTMLDialogElement
})
</script>

<template>
  <div v-if="!isLoading && collect">
    <div
      v-if="collectedContainers"
      class="flex items-start p-4 bg-base-100 border border-base-300 rounded-lg cursor-pointer"
      @click.prevent="navigateToCollect()"
    >
      <div>
        <div v-if="collectedContainerPreviewDocuments.length > 0" class="grid grid-cols-2 gap-2 w-20 h-full">
          <div v-for="container in collectedContainerPreviewDocuments" :key="container?.signedUrl" class="col-span-1 h-9">
            <ImageWrapper
              v-if="container?.signedUrl"
              :key="container?.signedUrl"
              class="h-full rounded object-cover"
              :src="container?.signedUrl"
              :alt="container?.name"
            />
          </div>
        </div>
        <div v-else class="flex gap-2">
          <BoxIcon :size="36" />
          <div class="h-9 w-9" />
        </div>
      </div>
      <div v-if="collectedContainers.length > 0" class="pl-3 flex flex-col w-full items-start gap-2">
        <div class="flex justify-between w-full">
          <div class="flex items-center gap-2 overflow-hidden">
            <div class="flex items-center gap-2 truncate">
              <span v-for="container in collectedContainersNameSignedUrl" :key="container.name" class="truncate">
                <Tag :tag="container.name" class="truncate" />
              </span>
            </div>
          </div>

          <div class="flex items-center flex-row gap-1">
            <StarRating
              v-if="collect.npsRating"
              v-model:rating="collect.npsRating.rating"
              :star-size="16"
              :show-rating="false"
              read-only
              class="cursor-pointer"
            />

            <OptionsDropdown
              v-if="isProducer"
              v-model="selectedStatus"
              @cancel-collect="openCancelCollectModal"
              @edit-collect="editService(collect.id)"
            />
          </div>
        </div>

        <div class="flex gap-2 text-sm w-full">
          <div class="flex gap-1 items-center">
            <Calendar :size="18" />
            <span> {{ startsAt }} - {{ endsAt }}</span>
          </div>
        </div>

        <div class="flex items-center gap-4 justify-between w-full">
          <div class="w-full">
            <div v-if="selectedStatus === CollectStatus.WpCancel" class="flex items-center gap-2">
              <Tag tag="Cancellation requested" class="bg-red-200 text-red-800" />
            </div>

            <div v-else-if="collect.status" class="flex items-center justify-between w-full gap-4">
              <p v-if="collectedContainers[0]?.material?.activePricing?.price">
                <span class="font-bold text-sm">
                  + {{ collectMaterialsPrice }}
                </span>
                <span class="text-sm text-base-content/40"> / {{ $t("global.per_ton") }} </span>
              </p>
              <div v-else />

              <Tag
                :tag="t(`collect.status_${selectedStatus}`)"
                :tag-classes="statusColor.find((el) => el.status === selectedStatus)"
              />
            </div>
          </div>
        </div>
        <template v-if="isEditingService">
          <div class="flex flex-col gap-0.5 w-full max-w-[600px]">
            <p class="text-sm">
              {{ `${t("global.referent")} :` }}
            </p>
            <WSelect
              id="referent"
              v-model="selectedReferent"
              :options="referentOptions"
            />
          </div>

          <div class="flex flex-col gap-0.5 w-full max-w-[600px]">
            <p class="text-sm">
              {{ `${t("global.edit_note")} :` }}
            </p>
            <textarea v-model="notes" class="textarea" rows="4" />
          </div>
        </template>
      </div>
    </div>
    <CancelCollectModal id="cancel_collect_modal" v-model="modalStatus" :collect="collect" />
  </div>
</template>
