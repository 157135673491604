<script setup lang="ts">
import { XIcon } from 'lucide-vue-next'
import { isMobile } from '~/composables/breakpoints'

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: 'Title',
  },
  size: {
    type: String as PropType<ModalSize>,
    required: false,
    default: 'md',
  },
  id: {
    default: 'modal',
    required: true,
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  cancelFooter: {
    type: Boolean,
    default: true,
  },
})

const open = defineModel<boolean>()

const { t } = useI18n()

type ModalSize = 'sm' | 'md' | 'lg' | 'xl'

function sizeToPercent(size: ModalSize) {
  if (isMobile.value) {
    return 'w-full'
  }
  switch (size) {
    case 'sm':
      return 'w-1/3'
    case 'md':
      return 'w-1/2'
    case 'lg':
      return 'w-3/5'
    case 'xl':
      return 'w-3/4'
  }
}

const { size, id, loading } = toRefs(props)
</script>

<template>
  <Teleport to="body">
    <dialog :id="id" class="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="modal-box max-w-5xl" :class="sizeToPercent(size)">
        <form method="dialog">
          <button class="btn btn-sm btn-circle btn-ghost absolute right-4 top-4" @click="open = false">
            <XIcon :size="18" />
          </button>
        </form>
        <div v-if="!loading">
          <div class="bg-base-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="flex">
              <div class="w-full">
                <h3 id="modal-title" class="text-lg font-medium leading-6 text-base-content pb-3">
                  <slot name="title">
                    {{ title }}
                  </slot>
                </h3>
                <slot />
                <div class="modal-action">
                  <form v-if="cancelFooter" method="dialog">
                    <button class="btn btn-sm btn-ghost" @click="open = false">
                      {{ t("global.cancel") }}
                    </button>
                  </form>
                  <slot name="footer">
                    <!-- <button class="btn btn-sm btn-primary" @click="emit('ok')">
                        {{ t("global.validate") }}
                      </button> -->
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader v-else />
      </div>
      <form method="dialog" class="modal-backdrop">
        <button @click="open = false">
          close
        </button>
      </form>
    </dialog>
  </Teleport>
</template>
