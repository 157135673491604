import type { ApolloQueryResult } from '@apollo/client'
import type { Maybe } from 'graphql/jsutils/Maybe'
import { provideApolloClient } from '@vue/apollo-composable'
import { DELETE_STORED_DOCUMENT } from '~/queries/documents'
import { CREATE_USER, UPDATE_USER } from '~/queries/user'
import {
  GET_CURRENT_USER,
  TOGGLE_TRACKDECHETS_AUTOSIGN,
  USERS_NEST_QUERY_LIST,
} from '~/queries/users'
import type {
  CreateUserInput,
  CurrentUserGraphql,
  PaginationArgs,
  Query,
  UpdateUserInput,
  UserFilterGraphql,
  UserGraphql,
} from '~/types/graphql-backend-types/gql-types'
import apolloClient from '../composables/graphql'

provideApolloClient(apolloClient)

export const useUsersStore = defineStore('users', () => {
  const { query, mutate } = useGqlMikro()

  const users = ref<UserGraphql[]>([])
  const user = ref() as Ref<CurrentUserGraphql>
  const userSetForUpdate = ref<UserGraphql>()
  const usersLoading = ref(false)
  const usersCount = ref<number>(0)
  const stores_loaded = ref(false)
  const stores_loading_msg = ref('Loading stores...')
  const page = ref<number>(1)

  const offset = computed(() => (page.value - 1) * 10)

  const filters = reactive({
    search: '' as string,
    ids: [],
  })

  watch(filters, async () => {
    page.value = 1
    await loadUsersNest({})
  }, { deep: true })

  watch(page, async () => {
    await loadUsersNest({})
  })

  const getUsersAsOptions = computed(() => {
    return users.value.map((item) => {
      const function_name = item?.function ?? ''
      const function_suffix = function_name ? ` - ${function_name}` : ''
      return {
        label: `${item.firstName} ${item.lastName}${function_suffix}`,
        value: item.id,
      }
    })
  })

  const activeUsers = computed(() => {
    return users.value.filter(u => u.disabled === false)
  })

  const disabledUsers = computed(() => {
    return users.value.filter(u => u.disabled === true)
  })

  const isAlreadyConnectedToTD = computed(() => {
    if (user?.value?.client?.isConnectedToTrackdechets) {
      return true
    }
    return false
  })

  async function loadUsersNest(f: UserFilterGraphql): Promise<Maybe<UserGraphql>[]> {
    const { query } = useGqlMikro()

    if (!isAdmin.value)
      return []
    const userFilters: UserFilterGraphql = {}
    if (filters.search)
      userFilters.search = filters.search
    if (f.ids) {
      // reset search filters as it collides with user ids for update
      userFilters.search = ''
      userFilters.ids = f.ids
    }
    usersLoading.value = true
    const { data, errors } = await query({
      query: USERS_NEST_QUERY_LIST,
      variables: {
        pagination: { limit: 10, offset: offset.value } as PaginationArgs,
        filters: userFilters,
      },
    })
    usersLoading.value = false
    if (errors) {
      throw new Error(errors[0].message)
    }
    usersCount.value = data!.users.count
    users.value = JSON.parse(JSON.stringify(data.users.collection)) as UserGraphql[]
    return data.users.collection
  }

  async function loadUserById(f: UserFilterGraphql): Promise<Maybe<UserGraphql>> {
    const { query } = useGqlMikro()

    if (!isAdmin.value)
      return
    const userFilters: UserFilterGraphql = {}
    if (filters.search)
      userFilters.search = filters.search
    if (f.ids) {
      // reset search filters as it collides with user ids for update
      userFilters.search = ''
      userFilters.ids = f.ids
    }

    const { data, errors } = await query({
      query: USERS_NEST_QUERY_LIST,
      variables: {
        pagination: { limit: 1, offset: 0 } as PaginationArgs,
        filters: userFilters,
      },
    })
    if (errors) {
      throw new Error(errors[0].message)
    }
    userSetForUpdate.value = JSON.parse(JSON.stringify(data.users.collection[0])) as UserGraphql
    return data.users.collection[0]
  }

  async function updateUserNest(user: UpdateUserInput) {
    const { mutate } = useGqlMikro()
    const { data, errors, validationError } = await mutate({ mutation: UPDATE_USER, variables: { input: user } })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    const userIndex = users.value.findIndex(u => u.id === data?.updateUser?.id)
    if (user) {
      users.value.splice(userIndex, 1, data!.updateUser)
    }
    return { data, errors, validationError }
  }

  const isRecycler = computed(() => {
    // @ts-expect-error __typename is accessible in the schema
    return user.value?.client?.__typename === 'RecyclerGraphql'
  })

  const isProducer = computed(() => {
    // @ts-expect-error __typename is accessible in the schema
    return user.value?.client?.__typename === 'ProducerGraphql'
  })

  const isAdmin = computed(() => {
    return user.value?.user?.role === 'admin_app'
  })

  // @ts-expect-error producers is present in RecyclerGraphQL
  const getClientProducers = computed(() => user?.value?.client?.producers?.collection ?? [])

  async function removePictureProfile(userId: string) {
    const { data } = await mutate({
      mutation: DELETE_STORED_DOCUMENT,
      variables: { input: { id: userId } },
    })

    user.value.client.documents.collection.pop()
    return data?.deleteStoredDocument
  }
  async function getCurrentUser() {
    const { data, errors } = await query({ query: GET_CURRENT_USER }) as ApolloQueryResult<Query>

    if (data === null && errors && errors.length > 0) {
      console.error(errors[0].message)
      return
    }

    user.value = JSON.parse(JSON.stringify(data.me))

    return data?.me?.client ?? {}
  }

  async function createUser(payload: CreateUserInput): Promise<{ data: any, validationError: any, errors: any }> {
    const { mutate } = useGqlMikro()

    const { data, validationError, errors } = await mutate({ mutation: CREATE_USER, variables: { input: payload } })

    if (data === null && errors && errors.length > 0) {
      throw new Error(errors[0].message)
    }

    await loadUsersNest({})
    return { data, validationError, errors }
  }

  async function toggleTrackDechetAutosign() {
    const { data } = await mutate({ mutation: TOGGLE_TRACKDECHETS_AUTOSIGN })
    return data
  }

  return {
    users,
    user,
    usersLoading,
    page,
    usersCount,
    userSetForUpdate,
    isAlreadyConnectedToTD,
    stores_loaded,
    stores_loading_msg,
    filters,
    isRecycler,
    getClientProducers,
    isProducer,
    isAdmin,
    getUsersAsOptions,
    activeUsers,
    disabledUsers,
    createUser,
    loadUsersNest,
    loadUserById,
    updateUserNest,
    getCurrentUser,
    toggleTrackDechetAutosign,
    removePictureProfile,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore, import.meta.hot))
}
