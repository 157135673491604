<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ArrowDownZAIcon, ArrowUpAZIcon } from 'lucide-vue-next'
import { AscDescGraphql } from '~/types/graphql-backend-types/gql-types'

const model = defineModel()

function updateModelValue(value: string, close = () => {}) {
  model.value = value
  close()
}
</script>

<template>
  <Popover v-slot="{ close }" class="relative flex-shrink-0">
    <PopoverButton
      class=" text-sm btn btn-sm btn-neutral text-neutral-content"
    >
      <ArrowUpAZIcon v-if="model === AscDescGraphql.Desc" :size="18" />
      <ArrowDownZAIcon v-else :size="18" />
      <div class="flex items-center gap-1">
        <p class="font-semibold">
          {{ `${$t('global.sort')} : ` }}
        </p>
        <p>
          {{ model === AscDescGraphql.Desc ? $t('global.more_recent') : $t('global.more_ancient') }}
        </p>
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PopoverPanel class="absolute left-0 top-9 z-10 min-w-48 flex">
        <div
          class="flex-auto overflow-hidden rounded bg-base-100 text-sm leading-2"
          style="box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15)"
        >
          <div class="flex flex-col py-2">
            <div
              class="w-full flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm hover:bg-neutral hover:text-neutral-content"
              :class="model === AscDescGraphql.Desc ? 'bg-neutral text-neutral-content' : ''"
              @click="updateModelValue(AscDescGraphql.Desc, close)"
            >
              <ArrowUpAZIcon :size="18" />
              {{ $t("global.more_recent") }}
            </div>
            <div
              class="w-full flex cursor-pointer items-center gap-2 px-3 py-1.5 text-sm hover:bg-neutral hover:text-neutral-content"
              :class="model === AscDescGraphql.Asc ? 'bg-neutral text-neutral-content' : ''"
              @click="updateModelValue(AscDescGraphql.Asc, close)"
            >
              <ArrowDownZAIcon :size="18" />
              {{ $t("global.more_ancient") }}
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
