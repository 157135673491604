import type { App } from 'vue'
import * as Sentry from '@sentry/vue'
import { VERSION } from '@wastex/common'

export function setupSentry(app: App, environment: string) {
  try {
    // Check if Sentry is blocked
    const isSentryBlocked = !window.fetch
      || typeof window.__SENTRY__ !== 'undefined'
      || document.querySelector('script[src*="sentry"]')?.getAttribute('blocked') === 'true'

    if (isSentryBlocked) {
      console.warn('Sentry appears to be blocked. Continuing without error tracking.')

      // Provide mock Sentry functions to prevent errors
      window.__SENTRY__ = {
        hub: {
          addBreadcrumb: () => {},
          captureException: () => {},
          captureMessage: () => {},
        },
      }

      return false
    }

    Sentry.init({
      app,
      dsn: 'https://09a42355a2adea643bfa0d5231fadfa5@o4507893276475392.ingest.de.sentry.io/4507893278703696',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracePropagationTargets: [
        'https://api.eugene.app/graphql',
        'https://app.eugene.app',
        'https://api-staging.eugene.app/graphql',
        'https://staging.eugene.app',
      ],
      beforeSend(event) {
        // Don't send events if Sentry appears to be blocked
        if (isSentryBlocked)
          return null
        return event
      },
      environment,
      release: VERSION,
      tracesSampleRate: environment === 'production' ? 0.1 : 1.0,
      replaysSessionSampleRate: environment === 'production' ? 0.1 : 1.0,
      replaysOnErrorSampleRate: 1.0,
    })

    return true
  }
  catch (error) {
    console.warn('Failed to initialize Sentry:', error)
    return false
  }
}

// Optional: Create a wrapper for error logging
export const errorLogger = {
  captureException: (error: Error, context?: Record<string, any>) => {
    try {
      Sentry.captureException(error, context)
    }
    catch {
      console.error('Error logging failed:', error, context)
    }
  },
  captureMessage: (message: string, level?: Sentry.SeverityLevel) => {
    try {
      Sentry.captureMessage(message, level)
    }
    catch {
      console.error('Message logging failed:', message)
    }
  },
}
